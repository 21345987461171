/*jshint esversion: 6 */
import './App.css';
import React, {useEffect, useState} from "react";
import {ServerIntf} from './ServerIntf';
import {Login} from './Login';
import Register from './Register';
import {Rules} from "./Rules";
import PasswordReset from "./PasswordReset";
import NonEgms from './NonEgms';
import Egms from './Egms';
import GameRecall from './GameRecall';
import GbbModal from './GbbModal';
import {NotificationContainer} from 'react-notifications';
import {ColorRing} from 'react-loader-spinner';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [isPrecall, setIsPrecall] = useState(false);
    const [rulesVisible, setRulesVisibility] = useState(false);
    const [context, setContext] = useState({ Version: 'unknown', SHA: 'not set' });
    const [recall, setRecall] = useState({FloorLocation: null, Games:[]});
    const [modalState, setModalState] = useState({ show: false, host: '', title: 'Download Host Report', modalType: ServerIntf.ModalType.REPORT });
    const [egms, setEgms] = useState([]);
    const [ess, setESS] = useState('');

    useEffect(() => {
        let contextSubscriber = ServerIntf.SubscribeContext((v) => setContext(v));
        let loginSubscriber = ServerIntf.SubscribeLogin((v) => setIsLoggedIn(v));
        let waitingSubscriber = ServerIntf.SubscribeWaiting((v) => setIsWaiting(v));
        let precallSubscriber = ServerIntf.SubscribePrecall((v) => setIsPrecall(v));
        let essSubscriber = ServerIntf.SubscribeESS((v) => setESS(v));
        let egmsSubscriber = ServerIntf.SubscribeEgms((v) => setEgms(v));
        let recallSubscriber = ServerIntf.SubscribeRecall((v) => setRecall(v));
        let modalSubscriber = ServerIntf.SubscribeModal((v) => setModalState(v));

        return function () {
            contextSubscriber.unsubscribe();
            loginSubscriber.unsubscribe();
            waitingSubscriber.unsubscribe();
            precallSubscriber.unsubscribe();
            essSubscriber.unsubscribe();
            egmsSubscriber.unsubscribe();
            recallSubscriber.unsubscribe();
            modalSubscriber.unsubscribe();
        }
    }, []);

    return (
        <div id="App" className="App">
            <nav className="navbar navbar-expand-lg navbar-dark justify-content-center"
                 style={{backgroundColor: 'rgb(22, 163, 71)'}}>
                <a href="/" style={{textDecoration: 'none'}}><span
                    className="navbar-brand abs">Grab Bag Bingo</span></a>
                <span className="navbar-text">{context.SHA}</span>
                {isLoggedIn && <button className="nav-link-button" onClick={ServerIntf.EnrollmentReport}>Enrollment Report</button>}
                {isLoggedIn ? (
                    <button className="nav-link-button" onClick={ServerIntf.AuditReport}>Audit Report</button>
                ) : (
                    <button className="nav-link-button" onClick={() => {
                        ServerIntf.SetModalState(ServerIntf.ModalType.PRECALL, 1);
                    }}>Preview Recall</button>
                )}
                <button className="nav-link-button" onClick={() => setRulesVisibility(true)}>Rules</button>
                {isLoggedIn && <button className="nav-link-button" onClick={ServerIntf.SignOff}>SignOff</button>}
            </nav>
            {isLoggedIn ? (
                <div className='container'>
                    <h3>Grab Bag Bingo Casino Management Tool</h3>
                    <Egms ess={ess} egms={egms}/>
                    <NonEgms ess={ess} players={egms}/>
                    <GameRecall ess={ess} recall={recall}/>
                </div>
            ) : window.location.pathname === "/Register" ? (
                <Register/>
            ) : window.location.pathname === "/PasswordReset" ? (
                <PasswordReset/>
            ) : isPrecall ? (
                <div className='container'>
                    <div className='container'><GameRecall ess={'preview'} recall={recall}/></div>
                </div>
            ) : (
                <Login/>
            )}
            <ColorRing visible={isWaiting} height="80" width="80" ariaLabel="blocks-loading"
                       wrapperClass={"loading-ring"} colors={['#94f503', '#5ef327', '#16f607', '#15860e', '#849b87']}/>
            <GbbModal state={modalState}/>
            <Rules visible={rulesVisible} onhide={() => setRulesVisibility(false)}/>
            <NotificationContainer/>
        </div>
    );
}

export default App;

// <Management />