import { useEffect } from "react";
import {Col, Container, Row} from 'react-bootstrap';

const BingoDraw = (props) => {
    const pubUrl = process.env.PUBLIC_URL;

    const animateBingoDraws = (delay = 25) => {
        const drawRows = document.getElementsByClassName("draw-row");

        for (const row of drawRows) {
            let children = row.getElementsByClassName("draw-item");

            for (let i = 0; i < children.length; i++) {
                setTimeout(() => {
                    children[i].style.visibility = "visible";
                }, delay);
                delay += 10;
            }
        }
    };

    useEffect(() => {
        animateBingoDraws();
    });

    return (
        <Container className="draw-col">
            <Row xs={7} md={10} lg={10} className="draw-row">
                {props.game.DrawOrder.split(" ").map(function (draw) {
                    if (!draw)
                        return;
                    if (RegExp(/^\d+$/).test(draw)) {
                        return (
                            <Col className="draw-item">
                                <img src={pubUrl + "/bingo-assets/ball.svg"} className="ball"/>
                                <span className="ball-txt">{draw}</span>
                            </Col>
                        );
                    } else {
                        return (
                            <Col className="draw-item">
                                <img src={pubUrl + "/bingo-assets/ball.svg"} className="ball"/>
                                <img
                                    src={draw.includes("G") ? pubUrl + "/bingo-assets/god.svg" : pubUrl + "/bingo-assets/constellation.svg"}
                                    className="ball-symbol"/>
                            </Col>
                        );
                    }
                })}
            </Row>
        </Container>
    );
}

export default BingoDraw;