import './App.css';
import './GameRecall.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { memo } from "react";
import BingoDraw from "./BingoDraw";
import BingoCard from "./BingoCard";

const GameRecall = (props) => {
    let games = [];

    if(props.recall.GameRecallJson) {
        if (props.ess === 'preview') {
            games = props.recall.GameRecallJson;
            props.recall.FloorLocation = games[0].FloorLocation;
        } else {
            games = JSON.parse(props.recall.GameRecallJson);
        }
        games.sort((a, b) => (a.WagerTime > b.WagerTime ? -1 : 1));
    }

    const WagerTime = (row) => {
        return new Date(row.WagerTime).toLocaleString('en-US', {hour12: false});
    };

    const EndTime = (row) => {
        if (row.WagerAmount === 0) {
            return WagerTime(row);
        }
        return new Date(row.EndTime).toLocaleString('en-US', {hour12: false});
    };

    const StartCredits = (row) => {
        return row.StartCredits === 0 ? '-' : row.StartCredits;
    };

    const WagerAmount = (row) => {
        return row.WagerAmount === 0 ? '-' : row.WagerAmount;
    };

    const TotalPrize = (row) => {
        return row.EndTime === 0 ? '-' : row.TotalPrize;
    };

    const EndCredits = (row) => {
        return row.EndTime === 0 ? '-' : row.EndCredits;
    };

    const BingoDrawAnimation = (row) => {
        return <BingoDraw game={row}></BingoDraw>;
    };

    const BingoCardAnimation = (row) => {
        return <BingoCard game={row}></BingoCard>;
    }

    return (
        <div className="table-wrapper">
            <h4 className="table-name">Game Recall - {props.recall.FloorLocation} ({props.ess})</h4>
            <DataTable value={games} stripedRows showGridlines columnResizeMode="expand" dataKey="WagerTime" scrollable
                       scrollHeight="400px" tableStyle={{minWidth: '50rem'}}>
                <Column header="Card ID" field="CardID"></Column>
                <Column header="Game Start" field={WagerTime}></Column>
                <Column header="Game End" field={EndTime}></Column>
                <Column header="Draw" body={BingoDrawAnimation}></Column>
                <Column header="Spots Hit" body={BingoCardAnimation}></Column>
                <Column header="Start Credits" field={StartCredits}></Column>
                <Column header="Wager" field={WagerAmount}></Column>
                <Column header="Win" field={TotalPrize}></Column>
                <Column header="End Credits" field={EndCredits}></Column>
            </DataTable>
        </div>
    );
}

export default memo(GameRecall);